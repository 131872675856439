@import 'src/include.less';

// TODO: Should use the ts-colors when we replaced them with re-branded ones
@blue: #0a37f0;
@gray: #f7f8fa;

// Check public/index.html for more rules
.main-loading {
	.ts-mixin-unselectable();

	&,
	.loading-container {
		.ts-mixin-maximize();
		z-index: 10000;
	}

	& {
		// will-change: opacity;
	}

	.loading-container {
		// will-change: transform;
	}

	#loading-logo {
		.ts-mixin-maximize(50%, inherit, inherit, 50%);
		@length: 64px;
		margin-top: -(@length / 2);
		margin-left: -(@length / 2);
		width: @length;
		height: @length;

		path {
			fill: @blue;
		}
	}

	#loading-text,
	#loading-subtext {
		.ts-mixin-maximize(50%, 0, inherit, 0);
		margin-top: 3.75 * @ts-unit;
		color: @ts-white;
		text-align: center;
		font-size: @ts-fontsize;
		font-weight: @ts-fontweight;
		line-height: @ts-unit-plus;
		margin-bottom: @ts-unit-plus;
	}

	#loading-subtext {
		margin-top: 5.5 * @ts-unit;
		font-size: @ts-fontsize-mini;
	}

	.loading-button {
		.ts-mixin-maximize(50%, 0, inherit, 0);
		// will-change: opacity;
		color: @ts-white;
		text-align: center;
		text-transform: uppercase;
		font-size: @ts-fontsize;
		font-weight: @ts-fontweight;
		line-height: @ts-unit-double;
		margin-bottom: @ts-unit-plus;
		padding: @ts-unit;
	}

	#loading-reload {
		margin-top: 8.75 * @ts-unit;
		text-transform: uppercase;
	}

	#loading-status {
		margin-top: 8.75 * @ts-unit;
	}

	#loading-support {
		margin-top: 10.75 * @ts-unit;
	}

	#loading-logout {
		margin-top: 12.75 * @ts-unit;
	}

	#loading-text,
	#loading-logo {
		// will-change: opacity, transform, background;
		transform-origin: 50% 50%;
	}

	@duration: @duration-weird-tablet;

	&.fade-in {
		#loading-logo {
			animation: @duration loadingOpacityFadeIn @easing-weird;
			animation-iteration-count: 1;
		}

		#loading-text {
			@long-duration: (@duration * 2);
			animation: @long-duration loadingOpacityFadeIn @easing-leave;
			animation-iteration-count: 1;
		}

		.loading-container {
			animation: @duration loadingTransformFadeIn @easing-weird;
			animation-iteration-count: 1;
		}
	}

	&.fade-out {
		animation: @duration loadingOpacityFadeIn @easing-weird reverse;
		animation-iteration-count: 1;
		opacity: 0;
		pointer-events: none;

		.loading-container {
			animation: @duration loadingTransformFadeIn @easing-weird reverse;
			animation-iteration-count: 1;
		}
	}

	&.anim {
		#loading-logo {
			animation: 4s loadingAnim @easing-weird;
			animation-iteration-count: infinite;
		}
	}

	&.error {
		animation: @duration loadingBgError @easing-weird;
		animation-iteration-count: 1;
		background: fade(@ts-black, 100%);

		#loading-logo {
			path {
				fill: @ts-color-white;
			}
		}

		#loading-text {
			animation: (@duration * 2) loadingTextError @easing-weird;
			animation-iteration-count: 1;
			transform: translateY(10px);
		}

		#loading-subtext {
			animation: (@duration * 2.5) loadingTextError @easing-weird;
			animation-iteration-count: 1;
			transform: translateY(10px);
		}

		.loading-button {
			animation: (@duration * 5) loadingOpacityFadeIn @easing-weird;
			animation-iteration-count: 1;
			opacity: 1;
		}
	}

	.error-information {
		bottom: @ts-unit-half;
		color: @ts-white;
		font-family: monospace;
		position: absolute;
		right: @ts-unit-half;
	}
}

.loader {
	position: relative;
	width: 7 * @ts-unit;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	&::before {
		content: '';
		display: block;
		padding-top: 100%;
	}

	&-circle {
		will-change: transform;
		animation: rotate 4s ease-in-out infinite;
		height: 100%;
		transform-origin: center center;
		width: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;

		&-path {
			will-change: stroke-dashoffset, stroke-dasharray;
			stroke-dasharray: 1, 200;
			stroke-dashoffset: 0;
			animation: dash 2s ease-in-out infinite;
			stroke-linecap: round;
			opacity: 1;
		}
	}

	.is-ie11 & {
		.loader-circle {
			will-change: transform;
			animation: rotate 2s linear infinite;

			&-path {
				stroke-dasharray: 90;
			}
		}
	}
}

@keyframes rotate {
	100% {
		transform: rotate(360deg) translateZ(0);
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35px;
	}
	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124px;
	}
}

@keyframes loadingTransformFadeIn {
	0% {
		transform: perspective(500px) translateZ(100px);
	}
	100% {
		transform: perspective(500px) translateZ(0);
	}
}

@keyframes loadingOpacityFadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes loadingAnim {
	0% {
		transform: perspective(500px) translateZ(0);
	}
	30% {
		transform: perspective(500px) translateZ(50px);
	}
	70% {
		transform: perspective(500px) translateZ(0);
	}
}

@keyframes loadingTextError {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(10px);
	}
}

@keyframes loadingBgError {
	0% {
		background: @gray;
	}
	100% {
		background: fade(@ts-black, 100%);
	}
}
