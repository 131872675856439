@import 'ts-variables.less';
@import 'ts-mixins.less';

* {
	margin: 0;
	padding: 0;
	border: none;
	-webkit-tap-highlight-color: transparent;
}
*,
*:before,
*:after {
	.ts-mixin-border-box();
	cursor: inherit;
}
*:focus {
	outline: none;
}
*::-moz-focus-inner {
	border: none;
}
*::-moz-focusring {
	outline: none;
}
::-moz-selection,
::-webkit-selection,
::selection {
	background: @ts-blue;
	color: @ts-white;
}
::placeholder {
	font-style: italic;
}
html,
body {
	margin: 0;
	padding: 0;
	height: 100%;
	overflow: hidden;
	background: @ts-lite-liter;
}
body {
	background-color: transparent !important;
	font-family: @ts-fontstack;
	font-weight: @ts-fontweight;
	color: @ts-black;
	font-size: @ts-fontsize;
	line-height: @ts-unit;
	position: relative;
	cursor: default;
}
