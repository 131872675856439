@import 'src/include.less';

// Menu ........................................................................
.menu {
	.ts-mixin-unselectable();
	.ts-mixin-maximize(0, ~'calc(100% - @{width-menu})');
	position: absolute;
	left: 0;
	top: 0;
	width: @width-menu;
	z-index: @zindex-menu-large;
	background: @bg-menu-new;
	color: @fg-menu;

	.has-top-bar & {
		top: @height-menuitem;
	}

	> .container {
		.ts-mixin-maximize(0, 0, @height-menuitem);
		.device-mobile.has-anim & {
			.mixin-transition(transform, weird, mobile);
			.mixin-transition(bottom, weird, mobile);
			.mixin-transition(opacity, weird, mobile);
		}
		.device-small.has-anim & {
			.mixin-transition(transform, weird, tablet);
			.mixin-transition(bottom, weird, tablet);
			.mixin-transition(opacity, weird, tablet);
		}
		.device-medium.has-anim & {
			.mixin-transition(transform, weird, desktop);
			.mixin-transition(bottom, weird, desktop);
			.mixin-transition(opacity, weird, desktop);
		}
	}

	// Inbox+Apps ................................................................
	.menu-section {
		.header {
			.mixin-header-item();
			background: @bg-inactive;

			.device-mobile.has-anim & {
				.mixin-transition(opacity, weird, mobile);
				.mixin-transition(background, weird, mobile);
				z-index: @zindex-menu-header-footer;
			}
			.device-small.has-anim & {
				.mixin-transition(opacity, weird, tablet);
				.mixin-transition(background, weird, tablet);
				z-index: @zindex-menu-header-footer-small;
			}
			.device-medium.has-anim & {
				.mixin-transition(opacity, weird, desktop);
				.mixin-transition(background, weird, desktop);
				z-index: @zindex-menu-header-footer-large;
			}
		}

		.container {
			.ts-mixin-maximize(@height-menuitem);
			background: @bg-menu-new;
			overflow-x: hidden;
			overflow-y: auto;
			line-height: 0; //inline-block hack
		}

		&:not(.open) {
			.header {
				cursor: pointer;
			}
		}
	}

	&.menu-closed {
		width: @width-menu-closed;
		z-index: @zindex-mainframe-large + 1; // on top of the main frame

		.menu-item-label {
			.mixin-hide();
		}
	}

	&.menu-closed .menu-section .header {
		cursor: pointer;
	}

	// Mobile ....................................................................
	.device-mobile-only & {
		z-index: @zindex-menu;
		width: 100%;
		.header {
			.mixin-header-item(100%);
			background: @bg-menu-new;
			border-bottom: none;
		}

		&.menu-closed {
			.has-anim& {
				.mixin-transition(transform, leave, mobile);
			}
			.mixin-translate(-100vw, 0, 0);
		}
		&.menu-open {
			.has-anim& {
				.mixin-transition(transform, enter, mobile);
			}
			.mixin-translate(0, 0, 0);
		}
	}

	// Small & Medium & Large ....................................................
	.device-small-only & {
		.has-anim& {
			.mixin-transition(width, weird, tablet);
		}
		&.menu-closed {
			width: @width-menu-closed;
		}
	}

	// Medium & Large ............................................................
	.device-medium-only.has-anim & {
		.mixin-transition(width, weird, desktop);
	}

	// Large .....................................................................
	.device-large-only.collaboration-animating.has-anim & {
		.mixin-transition(width, weird, desktop);
	}

	// Hide the inbox in the panel, to keep the universalInbox frame for getting unread message
	.inbox {
		display: none;
	}
}

.device-large.menu-open.menu-animating.collaboration-closed {
	.menu + #frames-container .mainframe-container {
		width: ~'calc(100% - @{width-menu})';
	}
}
