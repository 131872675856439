@import 'src/include.less';

// @see More LESS/CSS in `src/components/menu/menu.less`

// Apps ........................................................................
.menu {
	.app-list {
		position: fixed;
		width: 100%;
		.ts-mixin-maximize();
		.apps-container {
			.mixin-transition(opacity, weird, mobile);
			background: @bg-menu-new;
			scrollbar-width: thin;
		}
		&.busy .apps-container {
			opacity: 0;
		}

		.installed-apps-container {
			position: relative;
		}

		&.closed {
			@tmp-height: 3 * @height-menuitem;
			.mixin-translate(0, 100%);
		}

		.device-mobile & {
			.has-anim& {
				&,
				.apps-container {
					.mixin-transition(transform, weird, mobile);
				}
			}
			z-index: @zindex-menu-apps;

			&:after {
				.has-anim& {
					.mixin-transition(opacity, weird, mobile);
				}
				z-index: @zindex-menu-header-footer - 1;
			}
			&:before {
				z-index: @zindex-menu-apps-sticky + 1;
			}
		}
		.device-small & {
			.has-anim& {
				.mixin-transition(transform, weird, tablet);
			}
			z-index: @zindex-menu-apps-small;

			&:after {
				.has-anim& {
					.mixin-transition(opacity, weird, tablet);
				}
				z-index: @zindex-menu-header-footer-small - 1;
			}
			&:before {
				z-index: @zindex-menu-apps-sticky-small + 1;
			}
		}
		.device-medium & {
			.has-anim& {
				.mixin-transition(transform, weird, desktop);
			}
		}
		.device-large & {
			z-index: @zindex-menu-apps-large;

			&:after {
				.has-anim& {
					.mixin-transition(opacity, weird, desktop);
				}
				z-index: @zindex-menu-header-footer-large - 1;
			}
			&:before {
				z-index: @zindex-menu-apps-sticky-large + 1;
			}
		}
	}

	.device-small-only.has-anim & {
		// Menu Opening & Open .....................................................
		&.menu-open {
			&.menu-animating {
				.app-list {
					.container {
						.mixin-transition(margin, weird, tablet);
						.mixin-transition(background, enter, tablet);
						overflow-y: auto;
					}
					.divider {
						// will-change+: opacity;
						transition+: opacity (@duration-weird-tablet / 4) @easing-weird
							(3 * @duration-weird-tablet / 4);
					}
					.app-name,
					.app-vendor,
					.menu-item-label {
						.mixin-transition(width, weird, tablet);
						// will-change+: opacity;
						transition+: opacity (@duration-weird-tablet / 4) @easing-weird
							(3 * @duration-weird-tablet / 4);
					}
					.installed-apps-container {
						.mixin-transition(opacity, enter, tablet);
					}
				}
			}
		}

		// Menu Closing & Closed ...................................................
		&.menu-closed {
			&.menu-animating {
				.app-list {
					.container {
						.mixin-transition(margin, weird, tablet);
						.mixin-transition(background, leave, tablet);
					}
					.divider {
						// will-change+: opacity;
						transition+: opacity (@duration-weird-tablet / 4) @easing-weird;
					}
					.app-name,
					.app-vendor,
					.menu-item-label {
						.mixin-transition(width, weird, tablet);
						// will-change+: opacity;
						transition+: opacity (@duration-weird-tablet / 4) @easing-weird;
					}
					.installed-apps-container {
						.mixin-transition(opacity, leave, tablet);
					}
				}
			}
		}
	}

	.device-medium.has-anim & {
		// Menu Opening & Open .....................................................
		&.menu-open {
			&.menu-animating {
				.app-list {
					.container {
						.mixin-transition(margin, weird, desktop);
						.mixin-transition(background, enter, desktop);
						overflow-y: auto;
					}
					.divider {
						// will-change+: opacity;
						transition+: opacity (@duration-weird-desktop / 4) @easing-weird
							(3 * @duration-weird-desktop / 4);
					}
					.app-name,
					.app-vendor,
					.menu-item-label {
						.mixin-transition(width, weird, desktop);
						// will-change+: opacity;
						transition+: opacity (@duration-weird-desktop / 4) @easing-weird
							(3 * @duration-weird-desktop / 4);
					}
					.installed-apps-container {
						.mixin-transition(opacity, enter, desktop);
					}
				}
			}
		}

		// Menu Closing & Closed ...................................................
		&.menu-closed {
			&.menu-animating {
				.app-list {
					.container {
						.mixin-transition(margin, weird, desktop);
						.mixin-transition(background, leave, desktop);
					}
					.app-name,
					.app-vendor,
					.menu-item-label {
						.mixin-transition(width, weird, desktop);
						// will-change+: opacity;
						transition+: opacity (@duration-weird-desktop / 4) @easing-weird;
					}
					.installed-apps-container {
						.mixin-transition(opacity, leave, desktop);
					}
				}
			}
		}
	}

	.device-small.no-anim &.menu-closed {
		.app-list {
			&:before,
			&:after {
				.mixin-hide();
			}
			.container {
				overflow-y: hidden;
				padding-right: 0 !important; // Overriding inline style
			}
			.divider,
			.app-name,
			.app-vendor,
			.installed-apps-container {
				.mixin-hide();
			}
		}
	}
	.device-small &.menu-closed {
		.menu-item-label {
			display: none;
			.mixin-hide();
		}
	}

	/* Tooltip CSS */

	[data-tooltip] {
		position: relative;

		&:before {
			background: @ts-color-black;
			border-radius: @ts-radius;
			color: @ts-color-white;
			content: attr(data-tooltip);
			height: @ts-unit-double;
			left: @width-menu-closed;
			line-height: @ts-unit-double;
			opacity: 0;
			padding: 0 @ts-unit-half;
			pointer-events: none;
			position: fixed;
			white-space: nowrap;
			margin: @ts-unit-quarter 0;
		}

		.menu-closed&:hover:before {
			.mixin-show();
			.mixin-transition(opacity, weird, mobile);
		}
	}
}
