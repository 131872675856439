@import 'src/include.less';

.menu-item-wrapper {
	display: block;
	width: ~'calc(100% - @{ts-unit-half})';
	padding: 0;
	margin: 0 @ts-unit-quarter @ts-unit-quarter;
	border-radius: @radius-app-icon;
	background: transparent;
	.mixin-transition(background, weird, desktop);

	button& {
		.mixin-transition(background, weird, desktop);
	}
}

&[data-tst-app^='Tradeshift\.'] .menu-item-label {
	.app-name {
		padding: @ts-unit-half 0;
	}

	.app-vendor {
		display: none;
	}
}

.menu-item {
	display: flex;
	align-items: center;
	width: 100%;
	border-radius: @radius-app-icon;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	&:hover,
	.menu-item-wrapper:focus > & {
		background-color: @bg-menu-item-hover;
	}

	.menu-item-wrapper &.active {
		background-color: @bg-menu-item-active;
	}

	.menu-closed & {
		width: calc(@size-app-icon + 2 * @ts-unit-quarter);
	}

	.menu-item-icon-wrapper {
		position: relative;
	}

	&.notification {
		.menu-item-icon-wrapper {
			&:after {
				position: absolute;
				left: calc(100% - @ts-unit-quarter);
				bottom: calc(100% - @ts-unit-quarter);
				width: @ts-unit-half;
				height: @ts-unit-half;
				border-radius: 50%;
				content: '';
				box-shadow: 0 0 0 2px @bg-menu-new;
				background-color: @ts-color-red-light;
			}
		}
	}

	.device-mobile.has-anim & {
		.mixin-transition(background, weird, mobile);
	}

	.device-small.has-anim & {
		.mixin-transition(background, weird, tablet);
	}

	.device-medium.has-anim & {
		.mixin-transition(background, weird, desktop);
	}
}

.menu-item-icon {
	padding: @ts-unit-quarter;
	display: inline-block;

	img {
		display: block;
		border-radius: @radius-app-icon;
		width: @size-app-icon;
		height: @size-app-icon;
	}
}

.menu-item-label {
	padding-left: @ts-unit-quarter;

	.device-small-only .menu-closed & {
		.mixin-hide();
	}
}
