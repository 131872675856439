@import 'src/include.less';

.modal-loading-cover {
	.ts-mixin-maximize();

	align-items: center;
	background-color: fade(@ts-black, 60%);
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: @zindex-top;

	.device-large & {
		z-index: @zindex-cover-large;
	}

	// copied from the ts elements spinner
	.spinner {
		animation: spin 1s linear infinite;
		border: 8px solid fade(@ts-color-gray-light, 50%);
		border-top-color: @ts-color-blue;
		border-radius: 50%;
		height: 80px;
		margin: @ts-unit;
		width: 80px;
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
