@import 'src/include.less';
@import 'src/less/tradeshift-ui/ts-fonts.less';
@import 'src/less/tradeshift-ui/ts-basic.less';
@import 'src/less/tradeshift-ui/ts-elements.less';

::-webkit-scrollbar {
	width: 5px;
}
::-webkit-scrollbar-thumb:vertical {
	margin: 5px;
	background-color: @ts-medium-liter;
	-webkit-border-radius: 5px;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
	height: 5px;
	display: block;
}

iframe {
	.ts-mixin-maximize();
	width: 100%;
	height: 100%;
	background: transparent;
}

.appcues iframe {
	position: initial;
	top: initial;
	left: initial;
	right: initial;
	bottom: initial;
}

.hide {
	.mixin-hide();
}

button {
	background: transparent;
}

button,
a {
	color: @ts-white;
}
