@import 'src/include.less';

// Zendesk Chat Iframes, button and popup
#webWidget,
#launcher {
	right: 0 !important;
	bottom: @ts-unit-triple !important;
	top: initial;
	left: initial !important;
	z-index: @zindex-zendesk-chat !important;
}

@media only screen and (min-width: 1424px) {
	#webWidget,
	#launcher {
		z-index: @zindex-zendesk-chat-large !important;
	}
}
