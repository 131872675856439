@import 'src/include.less';

// Cover ..............................................................
.mainframe-cover {
	.ts-mixin-maximize();
	background-color: fade(@ts-black, 60%);
	cursor: pointer;
	z-index: @zindex-cover;

	// Small & Medium & Large ....................................................
	.device-small.has-anim & {
		.mixin-transition(opacity, weird, tablet);
	}
	.device-large & {
		.has-anim& {
			.mixin-transition(opacity, weird, desktop);
		}
		z-index: @zindex-cover-large;
	}
}
