@import 'src/include.less';

.top-bar {
	background-color: @bg-menu;
	box-shadow: inset 0 0 1px 0 #000;
	display: flex;
	justify-content: space-between;
	height: @height-menuitem;

	.left-side-wrapper,
	.right-text {
		display: flex;
		align-items: center;
	}

	.left-icon:not(:empty) {
		margin-left: @ts-unit;
	}

	.left-text {
		display: inline-block;
		margin: 0 @ts-unit;
		white-space: nowrap;
	}

	.logo,
	.start-logo {
		max-height: @ts-unit-plus;
	}

	.start-logo {
		margin-left: @ts-unit;
	}

	.right-text {
		margin-right: @ts-unit;

		.device-mobile-only & {
			display: none;
		}
	}

	&.ie-11-deprecation-banner:hover {
		.left-text {
			text-decoration: underline;
		}
	}
}
