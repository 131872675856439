@import 'src/include.less';

// Header Item .................................................................
.mixin-header-item(@width: @width-menu) {
	position: relative;
	display: block;
	width: @width;
	height: @height-menuitem;
	background: @bg-inactive;
	border-bottom: 1px solid @bg-menu;

	.header-icon-container,
	.header-name,
	.item-action {
		position: absolute;
		top: 0;
		height: @height-menuitem;
	}
	.header-icon-container,
	.item-action {
		left: 0;
		width: @height-menuitem;
		img {
			width: @ts-unit;
			height: @ts-unit;
			margin: @ts-unit;
		}
	}
	.item-action {
		left: auto;
		right: 0;
		img {
			width: (@ts-unit * (3/4));
			height: (@ts-unit * (3/4));
			margin: (@ts-unit + (@ts-unit-quarter / 2));
		}
	}
	.header-name {
		left: @height-menuitem;
		line-height: @height-menuitem;
		font-size: @ts-fontsize-big;
		font-weight: @ts-fontweight-bold;
	}
}

.mixin-shadow() {
	display: block;
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	height: @ts-unit-half;
	background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(44, 44, 44, 0) 100%);
}
.mixin-top-shadow() {
	&:before {
		.mixin-shadow();
		top: @height-menuitem;
		transform: rotate(-180deg);
	}
}
.mixin-top-shadow-on() {
	&:before {
		.mixin-show();
	}
}
.mixin-top-shadow-off() {
	&:before {
		.mixin-hide();
	}
}

.mixin-ellipsis() {
	.ts-mixin-text-ellipsis();
	white-space: nowrap;
	overflow: hidden;
}

.mixin-hide() {
	opacity: 0;
	pointer-events: none;
}
.mixin-show() {
	opacity: 1;
	pointer-events: auto;
}

.mixin-svg-icon(@iconWidth: @width-icon-inner; @containerWidth: @ts-unit-triple; @containerHeight: @ts-unit-triple) {
	width: @iconWidth;
	height: @iconWidth;
	margin: ((@containerHeight - @iconWidth) / 2) ((@containerWidth - @iconWidth) / 2);
}

._inner-mixin-transition(@property; @time; @easing; @delayed; @multiplier)
	when(@delayed = delayed) {
	transition+: @property (@time * @multiplier) @easing (@time * @multiplier);
}
._inner-mixin-transition(@property; @time; @easing; @delayed; @multiplier) when(default()) {
	transition+: @property (@time * @multiplier) @easing;
}

.mixin-transition(@property; @type; @device; @delayed: false; @multiplier: 1)
	when(@type = weird)
	and(@device = mobile) {
	._inner-mixin-transition(@property, @duration-weird-mobile, @easing-weird, @delayed, @multiplier);
}
.mixin-transition(@property; @type; @device; @delayed: false; @multiplier: 1)
	when(@type = enter)
	and(@device = mobile) {
	._inner-mixin-transition(@property, @duration-enter-mobile, @easing-enter, @delayed, @multiplier);
}
.mixin-transition(@property; @type; @device; @delayed: false; @multiplier: 1)
	when(@type = leave)
	and(@device = mobile) {
	._inner-mixin-transition(@property, @duration-leave-mobile, @easing-leave, @delayed, @multiplier);
}

.mixin-transition(@property; @type; @device; @delayed: false; @multiplier: 1)
	when(@type = weird)
	and(@device = tablet) {
	._inner-mixin-transition(@property, @duration-weird-tablet, @easing-weird, @delayed, @multiplier);
}
.mixin-transition(@property; @type; @device; @delayed: false; @multiplier: 1)
	when(@type = enter)
	and(@device = tablet) {
	._inner-mixin-transition(@property, @duration-enter-tablet, @easing-enter, @delayed, @multiplier);
}
.mixin-transition(@property; @type; @device; @delayed: false; @multiplier: 1)
	when(@type = leave)
	and(@device = tablet) {
	._inner-mixin-transition(@property, @duration-leave-tablet, @easing-leave, @delayed, @multiplier);
}

.mixin-transition(@property; @type; @device; @delayed: false; @multiplier: 1)
	when(@type = weird)
	and(@device = desktop) {
	._inner-mixin-transition(
		@property,
		@duration-weird-desktop,
		@easing-weird,
		@delayed,
		@multiplier
	);
}
.mixin-transition(@property; @type; @device; @delayed: false; @multiplier: 1)
	when(@type = enter)
	and(@device = desktop) {
	._inner-mixin-transition(
		@property,
		@duration-enter-desktop,
		@easing-enter,
		@delayed,
		@multiplier
	);
}
.mixin-transition(@property; @type; @device; @delayed: false; @multiplier: 1)
	when(@type = leave)
	and(@device = desktop) {
	._inner-mixin-transition(
		@property,
		@duration-leave-desktop,
		@easing-leave,
		@delayed,
		@multiplier
	);
}
.mixin-transition(@property; @type; @device; @delayed: false; @multiplier: 1)
	when(@type = chrome-footer) {
	._inner-mixin-transition(
		@property,
		@duration-leave-desktop,
		@easing-chrome-footer,
		@delayed,
		@multiplier
	);
}

.mixin-pos(@left; @single-param: false) when(@single-param = false) {
	.mixin-pos(@left, width);
	.mixin-pos(@left, transform);
}
.mixin-pos(@left; @single-param: false) when(@single-param = width) and(@left = 0) {
	width: 100%;
}
.mixin-pos(@left; @single-param: false) when(@single-param = width) and(@left > 0) {
	width: ~'calc(100% - @{left})';
}
.mixin-pos(@left; @single-param: false) when(@single-param = transform) {
	.mixin-translate(@left, 0, 0);
}

.mixin-translate(@x; @y; @z: 0) {
	transform: translate3d(@x, @y, @z);
	.ts-engine-explorer & {
		transform: translate(@x, @y);
	}
}
.mixin-translate-y-minus(@y1; @y2) {
	transform: translate3d(0, @y1, 0) translate3d(0, -@y2, 0);
	.ts-engine-explorer & {
		transform: translate(0, @y1) translate(0, -@y2);
	}
}

.mixin-button-accent(@background: transparent, @activebackground: transparent, @bordercolor: transparent, @activebordercolor: transparent) {
	color: @ts-white;
	background-color: @background;
	&:after {
		transition: border-color @ts-transition-now;
		border: 1px solid @bordercolor;
		&:after {
			border-color: @bordercolor;
		}
	}
	&:focus:before {
		border-color: @activebordercolor;
	}
	&.ts-active:active {
		background-color: @activebackground !important;
		&:after {
			border-color: @activebordercolor !important;
		}
	}
}
