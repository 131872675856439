@import 'src/include.less';

// Header ......................................................................
.menu {
	.header {
		.ts-mixin-maximize(0, 0, ~'calc(100% - @{height-menuitem})');
		background: @bg-menu-new;

		.device-mobile & {
			z-index: @zindex-menu-header-footer;
		}
		.device-small & {
			z-index: @zindex-menu-header-footer-small;
		}
		.device-medium & {
			z-index: @zindex-menu-header-footer-large;
		}

		img {
			.mixin-svg-icon();
		}

		.left-container {
			.ts-mixin-maximize(0, ~'calc(100% - @{height-menuitem})', 0, 0);
			.menu-icon {
				.ts-mixin-maximize();
				.mixin-hide();
			}
		}

		.center-container {
			.ts-mixin-maximize(0, @height-menuitem, 0, @height-menuitem);

			.logo {
				.mixin-show();
				margin: 0 auto;
				display: block;
				width: auto;
				height: @ts-unit-triple;
			}
		}

		.right-container {
			.ts-mixin-maximize(0, 0, 0, ~'calc(100% - @{height-menuitem})');
			.close-icon {
				.ts-mixin-maximize();
				transform: scaleX(-1);
			}
		}

		.logo,
		.close-icon,
		.center-container,
		.right-container {
			.device-small.menu-open.has-anim & {
				.mixin-transition(opacity, enter, tablet);
			}
			.device-small.menu-closed.has-anim & {
				.mixin-transition(opacity, leave, tablet);
			}
			.device-medium.menu-closed.has-anim & {
				.mixin-transition(opacity, enter, desktop);
			}
			.device-medium.menu-open.has-anim & {
				.mixin-transition(opacity, leave, desktop);
			}
		}
		.menu-icon,
		.left-container {
			.device-small.menu-open.has-anim & {
				.mixin-transition(opacity, leave, tablet);
			}
			.device-small.menu-closed.has-anim & {
				.mixin-transition(opacity, enter, tablet);
			}
			.device-medium.menu-closed.has-anim & {
				.mixin-transition(opacity, leave, desktop);
			}
			.device-medium.menu-open.has-anim & {
				.mixin-transition(opacity, enter, desktop);
			}
		}

		.logo,
		.close-icon,
		.center-container,
		.right-container {
			.menu-open & {
				.mixin-show();
				cursor: pointer;
			}
			.menu-closed & {
				.mixin-hide();
			}
		}
		.menu-icon,
		.left-container {
			.menu-open & {
				.mixin-hide();
			}
			.menu-closed & {
				.mixin-show();
				cursor: pointer;
			}
		}
	}
}
