@import 'src/include.less';

// App Icon ....................................................................
.menu {
	.app-icon-img {
		.mixin-transition(opacity, enter, desktop);
		opacity: 0;

		&.loaded {
			opacity: 1;
		}
	}
}
