@import 'ts-variables.less';
@import 'ts-mixins.less';

html {
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-ms-overflow-style: scrollbar;
	-webkit-tap-highlight-color: transparent;
}
body {
	word-wrap: break-word;
}
p,
ul,
ol,
dl,
hr,
nav,
menu,
code,
form,
table,
figure {
	margin: 0 0 @ts-unit 0;
	&:last-child {
		margin-bottom: 0;
	}
}
nav,
menu {
	list-style: none;
}
ul[class],
ol[class] {
	list-style: none;
}
hr {
	border: none;
	background-color: transparent;
	color: transparent;
	border-bottom: 1px dotted @ts-lite-darker;
	margin: @ts-unit 0 (@ts-unit - 1px) 0;
}
samp,
code,
kbd,
var {
	font-family: @ts-fontstack-mono;
	font-size: 16px;
}
a {
	text-decoration: none;
}
button,
a {
	color: @ts-blue;
}
button:not([disabled]),
a {
	cursor: pointer;
	* {
		cursor: inherit;
	}
}
p a:hover {
	text-decoration: underline;
}
button {
	background-color: transparent;
	border: none;
}
input:not([type='checkbox']):not([type='radio']),
button,
select,
textarea {
	.ts-mixin-border-box();
	font-family: inherit;
	font-weight: inherit;
	font-size: inherit;
	line-height: inherit;
}
::-webkit-input-placeholder {
	color: @ts-medium-litest;
	font-style: italic;
}
input:-moz-placeholder {
	color: @ts-medium-litest;
	font-style: italic;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input::-ms-clear,
textarea::-ms-clear {
	display: none;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
	-ms-touch-action: manipulation;
	touch-action: manipulation;
}
table {
	border-collapse: collapse;
}
th,
td {
	vertical-align: top;
}
