@import 'src/include.less';

// MainFrame ...................................................................
.mainframe-container {
	.mixin-show();
	position: absolute;
	top: 0;
	height: 100%;
	.mixin-translate(0, 0, 0);
	background: @ts-white;
	z-index: @zindex-frames;

	.device-large & {
		z-index: @zindex-frames-large;
	}

	.has-top-bar & {
		top: @height-menuitem;
		height: calc(100% - @height-menuitem);
	}

	.main-frame {
		z-index: @zindex-mainframe;
	}

	// Mobile States .............................................................
	.device-mobile-only & {
		.mixin-pos(0);
	}

	// Small & Medium & Large Device States ......................................
	.device-small & {
		.mixin-pos(@width-menu-closed);
	}

	// Large Device States .......................................................
	.device-medium & {
		.has-anim& {
			.mixin-transition(transform, weird, desktop);
		}
		.main-frame {
			z-index: @zindex-mainframe-large;
		}
	}

	// Everything closed
	.device-medium.menu-closed.collaboration-closed &,
	// menu closing
	.device-medium.menu-closed.menu-animating.collaboration-closed &,
	// collaboration closing
	.device-medium.menu-closed.collaboration-closed.collaboration-animating &,
	// menu closing; collaboration closing
	.device-medium.menu-closed.menu-animating.collaboration-closed.collaboration-animating & {
		.mixin-pos(@width-menu-closed);
	}

	// menu open
	.device-medium.menu-open.collaboration-closed &,
	// menu opening; collaboration closing
	.device-medium.menu-open.menu-animating.collaboration-closed.collaboration-animating &,
	// menu closing; collaboration opening
	.device-medium.menu-closed.menu-animating.collaboration-open.collaboration-animating &,
	// collaboration open
	.device-medium.menu-closed.collaboration-open & {
		.mixin-pos(@width-menu-closed + @width-collaboration);
	}

	// Menu opening; Collaboration closed
	.device-medium.menu-open.collaboration-closed & {
		.mixin-pos(@width-menu);
	}

	// Menu opening; Collaboration open
	.device-large.menu-open.menu-animating.collaboration-open & {
		.mixin-pos(@width-menu + @width-collaboration, transform);
		.mixin-pos(@width-menu-closed + @width-collaboration, width);
	}

	// Menu closing; Collaboration open
	.device-large.menu-closed.menu-animating.collaboration-open & {
		.mixin-pos(@width-menu-closed + @width-collaboration);
	}

	// Everything open
	.device-large.menu-open.collaboration-open & {
		.mixin-pos(@width-menu + @width-collaboration);
	}

	// Everything opening
	.device-large.menu-open.menu-animating.collaboration-open.collaboration-animating & {
		.mixin-pos(@width-menu + @width-collaboration, transform);
		.mixin-pos(@width-menu-closed, width);
	}

	// Menu opening
	.device-large.menu-open.menu-animating.collaboration-closed & {
		.mixin-pos(@width-menu, transform);
		.mixin-pos(@width-menu-closed, width);
	}

	// Collaboration opening
	.device-large.menu-closed.collaboration-open.collaboration-animating & {
		.mixin-pos(@width-menu-closed + @width-collaboration, transform);
		.mixin-pos(@width-menu-closed, width);
	}

	// Menu open; Collaboration opening
	.device-large.menu-open.collaboration-open.collaboration-animating & {
		.mixin-pos(@width-menu + @width-collaboration, transform);
		.mixin-pos(@width-menu, width);
	}

	// Full Screen States ........................................................
	&.fullscreen {
		.mixin-pos(0) !important;
	}
}

.modal-container {
	position: absolute;
	.mixin-transition(left, weird, desktop);
	left: @width-menu-closed;
	top: 0;
	bottom: 0;
	right: 0;

	.device-mobile-only & {
		left: 0;
	}

	.has-top-bar & {
		top: @height-menuitem;
	}

	.device-large.menu-open & {
		.mixin-transition(left, weird, desktop);
		left: @width-menu;
	}

	.device-small.menu-open & {
		.mixin-transition(left, weird, tablet);
		left: @width-menu;
	}
}
