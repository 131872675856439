@import 'src/include.less';

.collaboration-container {
	top: 0;
	position: absolute;
	height: 100%;
	width: @width-collaboration;
	border-right: 1px solid @ts-lite-darker;
	.mixin-translate(0, 0, 0);

	.has-top-bar & {
		top: @height-menuitem;
		height: calc(100% - @height-menuitem);
	}

	// Mobile ....................................................................
	.device-mobile-only & {
		.mixin-show();
		z-index: @zindex-collaboration;
		width: 100%;
		&.collaboration-closed {
			.has-anim& {
				.mixin-transition(transform, leave, mobile);
			}
			.mixin-translate(-100vw, 0, 0);
		}
		&.collaboration-open {
			.has-anim& {
				.mixin-transition(transform, enter, mobile);
			}
			.mixin-translate(0, 0, 0);
		}
	}

	// Small & Medium & Large ....................................................
	.device-small & {
		z-index: @zindex-collaboration-small;
	}
	.device-small-only.has-anim & {
		&.collaboration-open {
			.mixin-transition(transform, weird, tablet);
		}
		&.collaboration-closed {
			.mixin-transition(transform, weird, tablet);
		}
	}
	.device-small-only.menu-animating.has-anim & {
		&.collaboration-open,
		&.collaboration-closed {
			.mixin-transition(transform, weird, tablet);
		}
	}

	.device-medium-only.has-anim & {
		&.collaboration-open {
			.mixin-transition(transform, weird, desktop);
		}
		&.collaboration-closed {
			.mixin-transition(transform, weird, desktop);
		}
	}
	.device-medium-only.menu-animating.has-anim & {
		&.collaboration-open,
		&.collaboration-closed {
			.mixin-transition(transform, weird, desktop);
		}
	}

	.device-large & {
		.has-anim& {
			.mixin-transition(transform, weird, desktop);
		}
		z-index: @zindex-collaboration-large;
	}

	&.collaboration-open {
		z-index: @zindex-collaboration-open-large;
	}

	// Everything closed
	.device-small.menu-closed.collaboration-closed & {
		.mixin-pos(@width-menu-closed - @width-collaboration, transform);
	}

	// Menu closing
	.device-small.menu-closed.menu-animating.collaboration-closed & {
		.mixin-pos(@width-menu-closed - @width-collaboration, transform);
	}

	// Collaboration closing
	.device-small.menu-closed.collaboration-closed.collaboration-animating & {
		.mixin-pos(@width-menu-closed - @width-collaboration, transform);
	}

	// Menu closing; Collaboration closing
	.device-small.menu-closed.menu-animating.collaboration-closed.collaboration-animating & {
		.mixin-pos(@width-menu-closed - @width-collaboration, transform);
	}

	// Menu open; Collaboration closing
	.device-small.menu-open.collaboration-closed.collaboration-animating & {
		.mixin-pos(@width-menu - @width-collaboration, transform);
	}

	// Menu open
	.device-small.menu-open.collaboration-closed & {
		.mixin-pos(@width-menu - @width-collaboration, transform);
	}

	// Menu opening; Collaboration closing
	.device-small.menu-open.menu-animating.collaboration-closed.collaboration-animating & {
		.mixin-pos(@width-menu - @width-collaboration, transform);
	}

	// Menu opening
	.device-small.menu-open.menu-animating.collaboration-closed & {
		.mixin-pos(@width-menu - @width-collaboration, transform);
	}

	// Menu closing; Collaboration opening
	.device-small.menu-closed.menu-animating.collaboration-open.collaboration-animating & {
		.mixin-pos(@width-menu-closed, transform);
	}

	// Collaboration open
	.device-small.menu-closed.collaboration-open & {
		.mixin-pos(@width-menu-closed, transform);
	}

	// Menu closing; Collaboration open
	.device-small.menu-closed.menu-animating.collaboration-open & {
		.mixin-pos(@width-menu-closed, transform);
	}

	// Collaboration opening
	.device-small.menu-closed.collaboration-open.collaboration-animating & {
		.mixin-pos(@width-menu-closed, transform);
	}

	// Menu opening; Collaboration open
	.device-small.menu-open.menu-animating.collaboration-open & {
		.mixin-pos(@width-menu, transform);
	}

	// Everything open
	.device-small.menu-open.collaboration-open & {
		.mixin-pos(@width-menu, transform);
	}

	// Everything opening
	.device-small.menu-open.menu-animating.collaboration-open.collaboration-animating & {
		.mixin-pos(@width-menu, transform);
	}

	// Menu open; Collaboration opening
	.device-small.menu-open.collaboration-open.collaboration-animating & {
		.mixin-pos(@width-menu, transform);
	}
}
