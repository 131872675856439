@import 'src/include.less';

// Footer ......................................................................
.menu {
	.footer {
		.ts-mixin-maximize(~'calc(100% - @{height-menuitem})');
		background: @bg-menu-new;

		.device-mobile & {
			z-index: @zindex-menu-header-footer;
			.mixin-transition(top, weird, mobile);
		}

		.device-small & {
			z-index: @zindex-menu-header-footer-small;
			.mixin-transition(top, weird, tablet);
		}

		.device-large & {
			z-index: @zindex-menu-header-footer-large;
			.mixin-transition(top, weird, desktop);
		}

		.footer-action-item {
			.device-small.menu-open & {
				.mixin-transition(opacity, enter, tablet);
			}

			.device-small.menu-closed & {
				.mixin-transition(opacity, leave, tablet);
			}

			.device-medium.menu-closed & {
				.mixin-transition(opacity, enter, desktop);
			}

			.device-medium.menu-open & {
				.mixin-transition(opacity, leave, desktop);
			}
		}

		.userimage,
		.user {
			top: 0;
		}

		.footer-toggle-wrapper,
		.footer-action-item {
			cursor: pointer;
		}

		.userimage {
			left: 0;
			width: @ts-unit-double;
			min-width: @ts-unit-double;
			text-align: center;

			.userimage-icon {
				display: block;
				width: @ts-unit-double;
				height: @ts-unit-double;
				border-radius: 50% 50%;

				&.userimage-fake {
					font-size: @ts-unit-double / 2.6;
					font-weight: @ts-fontweight-light;
					overflow: hidden;
					line-height: @ts-unit-double;
					text-align: center;
				}
			}
		}

		.user {
			flex-grow: 1;
			padding-left: @ts-unit-half;
			overflow: hidden;
			text-align: left;

			.username,
			.company {
				.mixin-ellipsis();
				display: block;
				line-height: @ts-unit;
				min-height: @ts-unit;
			}

			.username {
				font-size: @ts-fontsize;
			}

			.company {
				font-size: @ts-fontsize-mini;
				font-weight: @ts-fontweight;
			}
		}

		.footer-action-item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: calc(100% - @ts-unit-half);
			margin: @ts-unit-quarter;
			padding: @ts-unit-three-quarter @ts-unit-half;
			border-radius: @ts-unit-quarter;

			&:focus,
			&:hover {
				background-color: @bg-menu-footer-item-hover;
			}
		}

		.footer-toggle-icon {
			right: 0;
			margin-right: @ts-unit-half;
			padding: @ts-unit-half;
			transform: rotate(-90deg);
			transform-origin: 50% 50%;
			.mixin-transition(transform, chrome-footer, desktop);

			img {
				display: block;
			}
		}

		.footer-actions-list {
			&,
			&.show-branch-switcher {
				position: absolute;
				bottom: 100%;
				width: calc(100% - @ts-unit-half);
				left: @ts-unit-quarter;
				background: @bg-menu-item-hover;
				border-radius: @radius-app-icon;
				transform: scale(0.96) translate(@ts-unit-half, @ts-unit-half);
				transform-origin: 50% 100%;
				opacity: 0;
				pointer-events: none;
				.mixin-transition(all, chrome-footer, desktop);
			}
		}

		.footer-toggle-wrapper {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: @ts-unit-quarter;
			padding: @ts-unit-quarter;
			border-radius: @radius-app-icon;

			&:focus,
			&:hover {
				background-color: @bg-menu-item-hover;
			}
		}
	}

	&.menu-closed {
		.footer {
			overflow: hidden;
			.footer-action-item .footer-toggle-icon {
				.mixin-hide();
			}

			.userimage {
				cursor: pointer;
			}
		}
	}

	&.footer-open {
		.footer-toggle-wrapper {
			background: @bg-menu-item-hover;
		}

		.footer-toggle-icon {
			transform: rotate(90deg);
		}

		.footer-actions-list {
			transform: scale(1) translate(0px, 0px);
			opacity: 1;
			pointer-events: auto;
		}
	}
	.branch-switch-button {
		width: 100%;
	}

	.branch-switcher-wrapper {
		position: absolute;
		bottom: 100%;
		width: calc(100% - @ts-unit-half);
		left: @ts-unit-quarter;
		background: @bg-menu-item-hover;
		border-radius: @radius-app-icon;
		transform: scale(0.96) translate(@ts-unit-half, @ts-unit-half);
		transform-origin: 50% 100%;
		opacity: 0;
		pointer-events: none;
		.mixin-transition(all, chrome-footer, desktop);

		&.show-branch-switcher {
			transform: scale(1) translate(0px, 0px);
			opacity: 1;
			pointer-events: auto;
		}
	}
}
