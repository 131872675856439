@import 'src/include.less';

// Branch Switcher ..............................................................
.branch-switcher {
	padding: @ts-unit-quarter;
	.branch-switcher-header {
		font-size: @ts-unit-half;
		font-weight: @ts-fontweight-semibold;
		text-transform: uppercase;
		height: @ts-unit-double;
		line-height: @ts-unit-double;
		color: #6b849e;
		padding: 0 @ts-unit-half;
	}

	.branch-selector-link {
		display: block;
		text-align: center;
		margin: @ts-unit-quarter;
		width: calc(100% - @ts-unit-half);
		height: @ts-unit-double;
		line-height: @ts-unit-double;
		border: none;
		border-radius: @radius-app-icon;
		background: #007dcd;
		color: #fff;
		font-size: @ts-fontsize;
		transition: 0.15s all ease-in-out;
		box-shadow: 0 0 0 0 rgba(0, 72, 151, 1);
		&:hover {
			background-color: #0097ed;
		}
	}

	.branch-option {
		display: flex;
		align-items: center;
		width: 100%;
		padding: @ts-unit-quarter;
		border-radius: @radius-app-icon;
		&:hover {
			background-color: @bg-menu-footer-item-hover;
		}

		img {
			display: inline-block;
			width: @ts-unit-double;
			height: @ts-unit-double;
			border-radius: @radius-app-icon;
			background-color: @ts-color-white;
		}

		.branch-name {
			padding-left: @ts-unit-half;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
